<template>
    <div class="body">
        <canvas :id="indexOne" width="1840px" height="500px"></canvas>
        <!-- <canvas ref="canvasRef" :style="`height:calc( 100% - ${280 + 16}px);width:100%`" style="border-radius:25px; " /> -->
    </div>
</template>
<script>
    // import * as echarts from 'echarts';
    export default {
        props: {
            dataItem: {
                type: Object,
                required: true,
            },
            indexOne: {
                type: Number,
                required: true,
            },
            width: {
                type: Number,
                required: true,
                default: 1920,
            },
            // height: {
            //     type: Number,
            //     required: true,
            //     default: 2160 / 2,
            // },
        },
        data() {
            return {
            }
        },
        mounted() {
            this.getMap();

        },
        methods: {
            getMap() {
                // 获取设备像素比
                const getPixelRatio = context => {
                    // backingStore 获取浏览器像素和真实像素比
                    // 这个属性大部分浏览器都已废弃
                    const backingStore =
                        context.backingStorePixelRatio ||
                        context.webkitBackingStorePixelRatio ||
                        context.mozBackingStorePixelRatio ||
                        context.msBackingStorePixelRatio ||
                        context.oBackingStorePixelRatio ||
                        context.backingStorePixelRatio ||
                        1;
                    return (window.devicePixelRatio || 1) / backingStore;
                };
                const canvas = document.getElementById(this.indexOne);
                const ctx = canvas.getContext('2d');
                const ratio = getPixelRatio(ctx);
                // canvas.width = canvas.width * ratio;
                // canvas.height = canvas.height * ratio;
                console.log(canvas.width, canvas.height, ratio)


                // 把每列展示的数据整理出来
                var dataBox = this.dataItem.topo_list.node_list;
                var arr = [];
                dataBox.forEach(el => {
                    if (arr[el.deep]) {
                        if (el.state == 'danger') {
                            arr[el.deep].unshift(el)
                        } else {
                            arr[el.deep].push(el);
                        }
                    } else {
                        arr[el.deep] = [];
                        if (el.state == 'danger') {
                            arr[el.deep].unshift(el)
                        } else {
                            arr[el.deep].push(el);
                        }
                    }

                })
                // 数组只取前三列
                arr = arr.slice(0, 3);
                // 第二列数组只取前5个
                arr[1] = arr[1] ? arr[1].slice(0, 5) : [];
                // 深度为0的图标
                var img1 = 'https://h001.hongyunkeji.top/3b9dbf7373f9dc256b1462eff051624.png'
                // 深度为非0危险的图标
                var img2 = 'https://h001.hongyunkeji.top/75bbf0b8840af9d605ac7f95f955ece.png'
                // 深度为非0非危险的图标
                var img3 = 'https://h001.hongyunkeji.top/7b75888d541a7fd6d9c47c82e363377.png'
                // 计算第一列的x轴
                var x1 = Math.floor(canvas.width / 10);
                // 计算第二列的x轴
                var x2 = x1 * 2;
                // 计算第三列的x轴   每行10个
                var x3 = Math.floor((canvas.width - (x1 * 3)) / 11);
                // 把数据添加上图标和坐标
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] && arr[i].length > 0) {
                        // 计算第一第二列的y轴
                        var yone = Math.floor(canvas.height / (arr[i].length + 1));
                        // 第一  第二列竖向排列
                        if (i == 0 || i == 1) {
                            for (let j = 0; j < arr[i].length; j++) {
                                arr[i][j].x = i == 0 ? x1 : x2;
                                arr[i][j].y = yone * (j + 1);
                                arr[i][j].icon = i == 0 ? img1 : arr[i][j].state == 'danger' ? img2 : img3;
                            }

                        } else if (i == 2) {
                            // 第三列横向排列

                            // 计算第三列的y轴   每行10个
                            var arrNum = arr[i].length % 10 ? Math.floor(arr[i].length / 10) + 2 : Math.floor(arr[i].length / 10) + 1;
                            var y3 = Math.floor(canvas.height / arrNum);

                            for (let j = 0; j < arr[i].length; j++) {
                                // 当
                                arr[i][j].x = j % 10 ? (x1 * 2 + (j % 10 + 1) * x3) : x3 + x1 * 2;
                                arr[i][j].y = (Math.floor(j / 10) + 1) * y3;
                                arr[i][j].icon = arr[i][j].state == 'danger' ? img3 : img2;
                            }
                        }
                    }


                }
                // 根据坐标点把数据画上去
                arr.forEach(el => {
                    if (el && el.length > 0) {
                        for (let i = 0; i < el.length; i++) {
                            var img = new Image();   // 创建一个<img>元素
                            img.src = el[i].icon; // 设置图片源地址
                            img.onload = function () {
                                // 指定绘制图片的起始位置及尺寸（如果需要的话）
                                ctx.drawImage(img, el[i].x - 5, el[i].y - 8, 50, 50);
                            };

                            ctx.font = "10px sans-serif"
                            ctx.fillText(el[i].name, el[i].x, el[i].y + 50)

                        }
                    }

                })
                // 合并三个数组,在关系列表中增加画线的坐标点
                var mergedArray = arr[0].concat(arr[1], arr[2]);
                var linksBox = this.dataItem.topo_list.link_list;
                linksBox.forEach(el => {
                    for (let i = 0; i < mergedArray.length; i++) {
                        if (mergedArray[i] && mergedArray[i].name == el.source) {
                            el.aTo = [mergedArray[i].x, mergedArray[i].y];
                        }
                        if (mergedArray[i] && mergedArray[i].name == el.target) {
                            el.bTo = [mergedArray[i].x, mergedArray[i].y];
                        }
                    }
                })
                linksBox.forEach(el => {
                    if (el.aTo && el.bTo) {
                        ctx.beginPath();
                        ctx.moveTo(el.aTo[0], el.aTo[1] + 30);
                        ctx.lineTo(el.bTo[0] + 50, el.bTo[1] + 30);
                        ctx.setLineDash([3, 2]);  // [实线长度, 间隙长度]
                        ctx.lineDashOffset = -0;
                        ctx.stroke();


                    }
                })

                console.log(linksBox, mergedArray, 'linksBox')


            }
        },
    }
</script>



<style scoped lang="less">
</style>