<template>
  <router-view  />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
  margin: 0px;
}
@font-face {
  font-family: 'MyFont';
  src: url('./assets/PangMenZhengDaoBiaoTiTiMianFeiBan-2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
</style>
