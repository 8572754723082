<template>
    <div class="body">
        <div class="topBox">
            <div>
                <img src="../assets/topName.png" width="507px" height="58px" style="margin-left: 64px;margin-top:20px;"
                    alt="" srcset="">
            </div>
            <div class="topText">异常卡片记录01、02、03、04、05、06</div>
        </div>
        <div style="margin-top: 40px;">
            <div v-for="(item,i) in list" :key="i">
                <div class="cardBox"
                    :style="{background:(i+1)%2?'linear-gradient(261deg, rgba(92, 162, 255, 0.9) 0%, #4D93FF 62%, #4C6EFF 100%)':'linear-gradient( 261deg, #3CD0FF 0%, #00BBE9 62%, #00A9DE 100%)'}">
                    <div class="leftBox">
                        <div>
                            <div style="display: flex;align-items: center;">
                                <img src="../assets/leftIcon.png" style="width: 6px;height: 18px;" alt="">
                                <div class="titleBox">影响领域</div>

                            </div>
                            <div class="miniText">
                                {{item.nameOne}}
                            </div>
                        </div>
                        <div style="margin-top: 20px;">
                            <div style="display: flex;align-items: center;">
                                <img src="../assets/leftIcon.png" style="width: 6px;height: 18px;" alt="">
                                <div class="titleBox">异常领域</div>

                            </div>
                            <div class="miniTextOne">
                                {{item.nameTwo}}
                            </div>
                        </div>
                        <div style="margin-top: 20px;">
                            <div style="display: flex;align-items: center;">
                                <img src="../assets/leftIcon.png" style="width: 6px;height: 18px;" alt="">
                                <div class="titleBox">深度分析</div>

                            </div>
                            <div class="miniTextOne">
                                <div v-for="(items,j) in item.domian_card.description" :key="j">{{items}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="titleName">
                        异常{{'0'+(i+1)}}
                    </div>
                </div>
                <div class="linkBox">
                    <!-- <CardCanvas :dataItem="item" :width="getWith(list,i)" :height="list.length === 1 ? 2160 : 2160 / 2" /> -->
                    <cardBox :dataItem="item" :indexOne="'cavers'+i" :width="getWith(list,i)" :height="list.length === 1 ? 2160 : 2160 / 2" />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    // import * as echarts from 'echarts';
    // import { categories } from '../utils/categories'
    // import cardCanvas from './card-canvas.vue'
    import cardBox from './cardBox.vue'
    import data from './new_06.json'
    // import VertucalIcon from './Vertical.vue'
    export default {
          components: {
            // VertucalIcon,
            // CardCanvas: cardCanvas,
            cardBox:cardBox,
          },
        data() {
            return {
                list: [],
            }
        },
        mounted() {
            this.list = data;
            data.forEach(el => {
                var name = [];
                var name1 = [];
                el.domian_card.name.forEach(rll => {
                    name.push(rll.name);
                })
                el.domian_card.service.forEach(rll => {
                    name1.push(rll.name);
                })
                el.nameOne = name.join(',');
                el.nameTwo = name1.join(',');
            });
            // this.realTimes();


            // this.computedStyle()
        },
        methods: {
            // computedStyle() {
            //     const arrW = []
            //     this.list.forEach((element) => {
            //         if (element.topo_list.node_list.length > 80)
            //             arrW.push('xl')
            //         else if (element.topo_list.node_list.length > 50)
            //             arrW.push('l')
            //         else if (element.topo_list.node_list.length > 20)
            //             arrW.push('m')
            //         else if (element.topo_list.node_list.length > 0)
            //             arrW.push('s')
            //     })
            //     return {
            //         xl: arrW.filter(x => x === 'xl').length,
            //         l: arrW.filter(x => x === 'l').length,
            //         m: arrW.filter(x => x === 'm').length,
            //         s: arrW.filter(x => x === 's').length,
            //     }
            // },
            // getWith_c(nodes) {
            //     const width = window.innerWidth
            //     const result = this.computedStyle()

            //     let type = 'xl'
            //     if (nodes.length > 80)
            //         type = 'xl'
            //     else if (nodes.length > 50)
            //         type = 'l'
            //     else if (nodes.length > 20)
            //         type = 'm'
            //     else if (nodes.length > 0)
            //         type = 's'

            //     if (type === 'xl') {
            //         if (result.xl === 2)
            //             return width / 2 - 20
            //         else if (result.xl === 1)
            //             return width
            //     }
            //     if (type === 'l') {
            //         if (result.l === 2)
            //             return width / 2 - 20
            //         else if (result.l === 1)
            //             return width
            //     }
            //     if (type === 'm') {
            //         if (result.m === 2)
            //             return width / 3 - 20
            //         else if (result.m === 1)
            //             return width
            //     }
            //     if (type === 's') {
            //         if (result.s === 2)
            //             return width / 4 - 20
            //         else if (result.x === 1)
            //             return width
            //     }
            // },
            // getWith_d(nodes, index) {
            //     this.list.forEach((item) => {

            //     })
            //     const obj = {
            //         1: [1],
            //         2: [1, 1],
            //         3: [1 / 2, 1 / 2, 1 / 2],
            //         4: [1 / 2, 1 / 2, 1 / 2, 1 / 2],
            //         5: [1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3],
            //         6: [1 / 2, 1 / 2, 1 / 4, 1 / 4, 1 / 4, 1 / 4],
            //     }
            //     return obj[nodes.length][index] * window.innerWidth - 20
            // },
            getWith(nodes, index) {
                const arr = []
                this.list.forEach((item) => {
                    if (item.topo_list.node_list.length > 16)
                        arr.push('l')
                    else if (item.topo_list.node_list.length > 0)
                        arr.push('s')
                })
                const len = arr.filter(item => item === 'l').length
                let arrS = []
                if (len >= 3)
                    arrS = [...new Array(len).fill(1 / 2)]
                else
                    arrS = [...new Array(len).fill(1)]

                arrS = [...arrS, ...new Array(this.list.length - len).fill(1 / (this.list.length - len))]
                return arrS[index] * window.innerWidth - 20
            },


        },
    }
</script>



<style scoped lang="less">
    .body {
        width: 100%;
        background: url('../assets/bgIcon.png');
        background-size: 100% 100%;
    }

    .topBox {
        width: 100%;
        height: 104px;
        background: url('../assets/topbg.png');
        background-size: 100% 100%;
    }

    .topText {
        width: 100%;
        height: 48px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 32px;
        color: #E71E01;
        line-height: 38px;
        text-align: right;
        padding-right: 40px;
        box-sizing: border-box;
    }

    .cardBox {
        width: 1840px;
        min-height: 373px;
        border-radius: 20px;
        margin: 0 auto;
        padding: 32px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

    }

    .leftBox {
        width: 86%;
    }

    .miniText {
        height: 36px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 28px;
        margin-left: 26px;
        margin-top: 8px;
    }

    .miniTextOne {
        height: 24px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        letter-spacing: 4px;
        margin-left: 26px;
        margin-top: 8px;
    }

    .titleBox {
        height: 45px;
        font-family:MyFont;
        font-weight: 400;
        font-size: 32px;
        color: #FFFFFF;
        line-height: 38px;
        margin-left: 20px;
    }

    .titleName {
        height: 70px;
        font-family: MyFont;
        font-weight: 400;
        font-size: 50px;
        color: #FFFFFF;
        line-height: 59px;
    }

    .linkBox {
        width: 1840px;
        height:500px;
        margin: 0 auto;
    }
</style>