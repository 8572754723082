// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../view/monitorIndex.vue';
 
// 路由配置
const routes = [
  { path: '/', component: Home },
];
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});
 
export default router;